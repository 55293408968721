.CPCChartAnalyticsDiv {
    margin: 20px;
    width: 30vw;

}

.CPCChartAnalyticsTitle {
    padding-left: 15px;
    padding-top: 20px;
    font-size: 17px;
}

.CPCChartAnalyticsOrderTotal {
    padding-left: 15px;
    font-size: 23px;
    font-weight: 600;
}

.CPCChartAnalyticsChart {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
}