.profitChart {
    width: 35px;
}

.profitCard {
    background-color: rgb(103, 199, 184);
    color: #ffffff;
    font-family: 'Varela Round', sans-serif;
    border-radius: 20px;
    padding: 10px
}

.profitCardTitle{
    font-size: 20px;
    padding-left: 20px;
}

.profitCardQuantity {
    font-size: 25px;
    padding-left: 20px;
    font-weight: 500;
    color: #ffffff;

}

.surroundingProfitIcon {
    background-color: rgba(255, 255, 255, .15);
    height: 60px;
    width: 60px;
    border-radius: 9px;
    display: flex;
  align-items: center;
  justify-content: center;
}

.profitIcon {
    width: 35px;
    filter: invert(1);
    opacity: 100%;
    padding: auto;
    color: #B5BAC7
}

.profitCardDivider {
    border: 1px solid #B5BAC7;
    background-color: #B5BAC7;
    color: #B5BAC7;
    margin-left: 20px;
    margin-right: 20px;
}

.profitCardDateRange {
    padding: 20px;
    color: #4792FE;
}