.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
}
  
.accountingTableMainDiv {
    height: 99vh;
    padding: 10px;
    overflow-y: auto;
    width: 98%;
}

.AccountingTableLoadingContainer {
    margin-top: 48vh;
    display: block;
    width: 100%;
    text-align: center;
}

.AccountingTableLoadingText {
    display: block;    
    margin: auto;
    font-size: 18px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
}

.AccountingDetailsColumn {
    border-bottom: rgba(165, 165, 165, 0.308) solid 1px;
}

.AccountingDetailsRow:nth-child(odd) {background-color: #f7f7f7;}
.AccountingDetailsRow:hover {background-color: #f2f2f2;}

.AccountingDetailsRow {
    min-height: 2rem;
    height: 2.2rem;
    text-align: right;
    color: #3f4951;
}


.tableJSInputFieldsTable{
    width: 100%;
    table-layout: fixed;
    
}

.tableJSMainTable{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 8px;
    overflow-x: auto;
}

.tableJSMainTableContainer {
    
    max-width: 150vw;
    overflow-y: auto;

}

.AccountingDetailsTableHeader{
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 0.7);
    text-align: right;
}

.tableDataText{
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    text-align: right;
    cursor: default;
}

.title{
    font-size: 30px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
}

.tableJSHeaderRow {
    text-align: right;
}

.tableJSColumn {
    padding-right: 0px;
}


.AccountingTableRefreshButtonContainer {
    position: fixed;
    background-color: black;
    color: white;
    left: 45vw;
    bottom: 30px;
    border-radius: 10px;
    padding: 15px;
    display: flex;
}

.AccountingTableRefreshButtonText {
    display: block;
    margin-right: 20px;
}

.AccountingTableRefreshButton {
    text-decoration: underline;
    cursor: pointer;
}




/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .tableJSHideColsOnMobile {
        visibility:hidden;
        width: 0px;
        max-width: 0px;

    }

    .tableDataText{
        font-size: 11px;
        text-align: right;
    }

    .tableJSTableHeaders{
        font-size: 12px;
        color: "rgba(0, 0, 0, 0.87)";
        text-align: right;
    }
    .tableJSInputFieldsTable tr { display: block; float: left; }
    .tableJSInputFieldsTable th, .tableJSInputFieldsTable td { display: block; }

    .tableJSInputFields {
        width: 92vw;
        min-width: 92vw;
        padding-top: 5px;
    }

    .tableJSMainTable{
        width: 200vw;
        table-layout: fixed;
        border-collapse: collapse;
        margin-top: 8px;
        overflow-x: auto;
        overflow-y: auto;
    }

    .tableJSMainTableContainer {
        width: 100%;
        max-width: 92vw;
        overflow-x: auto;
    }
  }



/* DESKTOP */
@media (min-width:1100px) {
    .tableJSInputFields {
        width: 100%;
        max-width: 100%;
        padding-top: 5px;
        margin: auto;


    }


  }
