.AccountingTabsMainContainer {
    background-color: #ffffff;
}

.productPagesTabsTable {
    width: 100%;

}

.AccountingTabsContainer {
    overflow-y: auto;
    height: 100vh;
}


.tabRows {
    width: 100%;
}





.accountingTabsDetailsText{
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.descriptionContainer {
    padding-top: 5px;
}

.description{
    font-size: 15px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
    display: inline;
}

a {
    color: #000000;
    text-decoration: none; /* no underline */
  }

  .productPagesTabsSwitch {
    width: 20px;
  }

.accountingDetailsTabContainer {
    margin: 0px 20px 10px 20px;
}

.accountingTabSectionTitle {
    text-transform: uppercase;
    font-size: 12px;
    color: #586672;
    padding: 10px 0px 20px 35px;
    font-weight: 1000;
    padding-bottom: 10px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.accountingDetailsTab {
    background-color: rgba(192, 192, 192, 0.08);
    border-radius: 15px;
    cursor: pointer;
    color: rgb(106, 122, 136);
}

.accountingDetailsTab:hover {
    background-color: rgba(122, 122, 122, 0.107);
    border-radius: 15px;
    cursor: pointer;
    color: #6a7a88;
}

.activeAccountingDetailsTab {
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    margin: auto;
    color: rgb(0, 171, 85) !important;
    cursor: pointer;
}

.activeAccountingDetailsTab:hover {
    background-color: rgba(0, 171, 86, 0.109);
    border-radius: 15px;
    margin: auto;
    color: rgb(0, 171, 85) !important;
    cursor: pointer;
}

.productName{
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
    display: block;
    padding: 15px 5px 15px 15px;
}

.tabLeftCol {
    width:60%;
    height: 100%;
    display: inline-block;
}

.tabRightCol{
    width:33%;
    height: 100%;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    padding-right: 13px;

}

.productPageNavigationTabsContainer {
    padding: 10px;
    margin: 15px;
    border-radius: 20px;
}

.activeProductPageNavigationTabs {
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    margin: 0px 5px 0px 5px;
    color: rgb(0, 171, 85);
    cursor: pointer;
    width: 46%;
    font-family: 'Varela Round', sans-serif;
    display: inline-block;
}

.productPageNavigationTabs {
    background-color: rgba(192, 192, 192, 0.08);
    border-radius: 15px;
    margin: 0px 5px 0px 5px;
    color: #586672;
    cursor: pointer;
    width: 46%;
    font-family: 'Varela Round', sans-serif;
    display: inline-block;
}

.productPageNavigationTabsText {
    display: block;
    padding: 15px;
    font-size: 18px;
    text-align: center;
}

.accountingDrawerTitle {
    font-family: 'Varela Round', sans-serif;
    font-size: 24px;
    display: block;
    margin: auto;
    width: fit-content;
    padding: 20px;
    color: rgb(0, 171, 85);
    cursor: default;
}

.accountingDrawerSectionTitle {
    font-family: 'Varela Round', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    color: #637381;
    padding-left: 35px;
    font-weight: 800;
    padding-bottom: 10px;
    padding-top: 10px;
}

.accountingDrawerSection {
    font-family: 'Varela Round', sans-serif;
    color: #637381;
    font-size: 16px;
    border-radius: 15px;
    width: 85%;
    margin: auto;
    cursor: pointer;
    background-color: rgba(192, 192, 192, 0.08);
    margin-bottom: 10px;
}

.activeAccountingDrawerSection {
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    width: 85%;
    margin: auto;
    color: rgb(0, 171, 85);
}

.activeAccountingDrawerLinkTitle {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: rgb(0, 171, 85);
}

.accountingDrawerLinkTitle {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: #637381;
}