.drawerFont {
    font-family: 'Varela Round', sans-serif;
}

.dashboardMainContainer {
    display: flex;
}

.dashboardDrawer {
    font-family: 'Varela Round', sans-serif;
    width: 20vw;
    max-width: 20vw;
    float: left;
    position: sticky;
    height: 100vh;
    overflow-y: auto;
    background-color: #ffffff;
}

.dashboardDrawerTitleContainer {
    width: 100%
}

.dashboardDrawerTitle {
    font-family: 'Varela Round', sans-serif;
    font-size: 24px;
    display: block;
    margin: auto;
    width: fit-content;
    padding: 20px;
    color: rgb(0, 171, 85);
    cursor: default;
}

.dashboardDrawerUserSection {
    background-color: rgba(145, 158, 171, 0.12);
    width: 85%;
    margin: auto;
    border-radius: 20px;
}

.dashboardDrawerUserInformation {
    padding: 15px;
    text-align: center;
    cursor: default;
}

.dashboardDrawerUserName {
    color: #212B36;
    display: block;
}

.dashboardDrawerUserPriveledges {
    color: #637381;
}

.dashboardDrawerSectionTitle {
    text-transform: uppercase;
    font-size: 12px;
    color: #637381;
    padding: 35px;
    font-weight: 800;
    padding-bottom: 10px;
}

.dashboardDrawerSection {
    color: #637381;
    font-size: 16px;
    border-radius: 15px;
    width: 85%;
    margin: auto;
    cursor: pointer;
    background-color: rgba(192, 192, 192, 0.08);
    margin-top: 5px;
    margin-bottom: 10px;
}

.activeDashboardDrawerSection {
    color: #00ab55;
    font-size: 16px;
    border-radius: 15px;
    width: 85%;
    margin: auto;
    cursor: pointer;
    background-color: rgba(0, 171, 85, 0.078);
    margin-top: 5px;
    margin-bottom: 10px;
}

.activeDashboardDrawerLinkTitle {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: rgb(0, 171, 85);
}

.dashboardDrawerLinkTitle {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: #637381;
}

.dashboardDrawerSectionSignOut {
    font-size: 16px;
    border-radius: 15px;
    width: 85%;
    margin: auto;
    cursor: pointer;
    background-color: rgba(242, 60, 60, 0.08);
    margin-top: 5px;
    margin-bottom: 10px;
    text-align: center;
}

.dashboardDrawerLinkTitleSignOut {
    padding: 15px;
    display: block;
    text-decoration: none;
    color: #f7462e;
}


.dashboardBackground {
    margin: 0;
    padding: 0;
    background-color: #f4f3f3;
    min-height: 100vh;
    max-height: 100vh;
    width: 100vw;
    overflow-y: auto;
    font-family: 'Varela Round', sans-serif;
}

.dashboardNavbarContainer {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.6); /* Black w/opacity/see-through */
    padding-bottom: 10px;
    max-height: 50px;
    position: fixed;
    width: 85vw;
    z-index: 2;

}

.dashboardFunnelSearch {
    padding: 10px 0px 0px 50px;
}

.dashboardNavbarMediaBuyerDropdownContainer {
    position: absolute;
    top: 10px;
    right: 702px;
}

.dashboardNavbarDatepresetDropdownContainer {
    position: absolute;
    top: 10px;
    right: 550px;
}

.dashboardDateSelectors {
    position: absolute;
    top: 10px;
    right: 190px;
}

.dashboardRefreshButtonContainer {
    background-color: rgba(0, 171, 86, 0.16);
    border-radius: 15px;
    margin: auto;
    color: rgb(0, 171, 85);
    width: fit-content;
    position: absolute;
    top: 8px;
    right: 50px;
    cursor: pointer;
}

.dashboardRefreshButton {
    font-family: 'Varela Round', sans-serif;
    display: block;
    padding: 12px 25px 12px 25px;
}

.dashboardChartsRow1 {
    padding: 0 !important;
    display: flex;
    margin: auto;
    justify-content: center;
    max-width: 80vw;
}

.dashboardChart1 {
    margin-top: 80px;
    padding: 5px 5px 0px 5px !important;
    min-width: 19vw;
    max-width: 19vw;
    flex: 1;
}

.dashboardChart2 {
    margin-top: 80px;
    padding: 5px 5px 0px 5px !important;
    min-width: 19vw;
    max-width: 19vw;
    flex: 2;
}

.dashboardChart3 {
    margin-top: 80px;
    padding: 5px 5px 0px 5px !important;
    min-width: 19vw;
    max-width: 19vw;
    flex: 3;
}

.dashboardChart4 {
    margin-top: 80px;
    padding: 5px 5px 0px 5px !important;
    min-width: 19vw;
    max-width: 19vw;
    flex: 4;
}

.dashboardChartsRow2 {
    padding: 0 !important;
    display: flex;
    margin: auto;
    justify-content: center;
    max-width: 80vw;
    min-width: 80vw;
    margin-top: 20px;
}

.dashboardChartsRow2a {
    margin-right: 20px;
    width: 45vw;
    max-width: 45vw;
    flex: 1
}

.dashboardChartsRow2b {
    width: 35vw;
    max-width: calc(35vw - 60px);
    flex: 2;
    max-height: 500px;
}

.dashboardChartsRow3 {
    padding: 0 !important;
    display: flex;
    margin: auto;
    max-width: 80vw;
    min-width: 80vw;
    margin-top: 20px;    
}

.dashboardChartsRow3a {
    margin-right: 20px;
    margin-left: 20px;
    width: 30vw;
    max-width: 30vw;
    flex: 1
}

.dashboardChartsRow3b {
    max-width: 25vw;
    flex: 2
}

.dashboardChartsRow3b {
    max-width: 25vw;
    flex: 2
}


/*
.mediaBuyers {
    min-height: 500px;
    background-color: #ffffff;
    border-radius: 20px;
}
*/


/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .dashboardChartsRow1 {
        width: 100vw;
        margin: 0 auto;
        margin-top: 1vh;
        max-width: 100vw;
        
    }

    .dashboardChart {
        width:100vw;
        max-width:100vw;
        padding: 5px;
        display: block;
    }

    .dashboardDrawer {
        font-family: 'Varela Round', sans-serif;
        width: 0vw;
        max-width: 20vw;
        float: left;
        position: sticky;
        height: 100vh;
        overflow-y: auto;
        background-color: #ffffff;
    }

    .dashboardNavbarContainer {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.6); 
        padding-bottom: 60px;
        position: fixed;
        width: 100vw;
        z-index: 2;
    }
    
    .dashboardFunnelSearch {
        padding: 10px 0px 0px 20px;
    }
    
    .dashboardDateSelectors {
        position: absolute;
        top: 60px;
        right: 30px;
    }
    
    .dashboardRefreshButtonContainer {
        background-color: rgba(0, 171, 86, 0.16);
        border-radius: 15px;
        margin: auto;
        color: rgb(0, 171, 85);
        width: fit-content;
        position: absolute;
        top: 8px;
        right: 20px;
        cursor: pointer;
    }
    
    .dashboardRefreshButton {
        font-family: 'Varela Round', sans-serif;
        display: block;
        padding: 12px 25px 12px 25px;
    }

    .dashboardChartsRow1 {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
    }
    
    .dashboardChart1 {
        margin: auto;
        margin-top: 110px;
        padding: 5px 5px 0px 5px !important;
        min-width: 95vw;
        max-width: 95vw;
        flex: 1;
    }
    
    .dashboardChart2 {
        margin: auto;
        margin-top: 5px;
        padding: 5px 5px 0px 5px !important;
        min-width: 95vw;
        max-width: 95vw;
        flex: 2;
    }
    
    .dashboardChart3 {
        margin: auto;
        margin-top: 5px;
        padding: 5px 5px 0px 5px !important;
        min-width: 95vw;
        max-width: 95vw;
        flex: 3;
    }
    
    .dashboardChart4 {
        margin: auto;
        margin-top: 5px;
        padding: 5px 5px 0px 5px !important;
        min-width: 95vw;
        max-width: 95vw;
        flex: 4;
    }
    
    .dashboardChartsRow2 {
        padding: 0 !important;
        display: flex;
        margin: auto;
        justify-content: center;
        flex-direction: column;
        max-width: 100vw;
        min-width: 100vw;
        margin-top: 20px;
    }
    
    .dashboardChartsRow2a {
        margin: auto;
        width: 95vw;
        max-width: 100vw;
        flex: 1
    }
    
    .dashboardChartsRow2b {
        margin: auto;
        width: 95vw;
        max-width: 95vw;
        flex: 2
    }
    
    .dashboardChartsRow3 {
        padding: 0 !important;
        display: flex;
        margin: auto;
        flex-direction: column;
        max-width: 100vw;
        min-width: 100vw;
        margin-top: 0px;
    }
    
    .dashboardChartsRow3a {
        margin: auto;
        width: 95vw;
        max-width: 95vw;
        flex: 1
    }
    
    .dashboardChartsRow3b {
        width: 95vw;
        max-width: 95vw;
        flex: 2
    }

    .dashboardChartsRow3c {
        width: 95vw;
        max-width: 95vw;
        flex: 2
    }



  }









  /* TABLET */
@media (min-width:1100px) and (max-width: 2000px) {
    .dashboardChartsRow1 {
        width: 90vw;
        margin: 0 auto;
        margin-top: 1vh;
        padding: 10px
    }
    
    .dashboardChart {
        width:20vw;
        max-width:10vw;
        padding: 5px;
        padding-bottom: 20px;
    }
}



/* DESKTOP */
@media (min-width:2000px) {


  }