.userSettingsMainContainer {
    display: flex;
}

.userSettingsCardContainer {
    border-radius: 20px;
    padding: 100px 20px 20px 20px;
    margin: auto;
    width: 60%;
    background-color: #ffffff;
    margin-top: 95px;

}

.userSettingsAvatarContainer {
    width: fit-content;
    margin: auto;
    position: absolute;
    left: 54%;
    top: 20px
}

.userSettingsFullName {
    font-size: 32px;
    color: rgb(0, 171, 85);
    display: block;
    margin: auto;
    width: fit-content;
    padding-bottom: 10px;
    font-weight: 800;
}

.userSettingsEmployeeRole {
    font-size: 18px;
    display: block;
    margin: auto;
    width: fit-content;
    padding-bottom: 10px;
    font-weight: 400;
}

.userSettingsIDContainer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: right;
    font-size: 12px;
}

.userSettingsPasswordResetEmailButtonContainer {
    padding: 20px;
    width: fit-content;
    margin: auto;
}

.userSettingsTable {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.userSettingsSaveButtonContainer {
    width: fit-content;
    margin: auto;
    padding: 20px;
}

.userSettingsActiveDotContainer {
    position: absolute;
    top: 100px;
    right: 17%
}

.userSettingsActiveDot {
    height: 10px;
    width: 10px;
    background-color: #24e403;
    border-radius: 50%;
    display: inline-block;
    line-height: 14px;
    margin: auto;
  }