.subscribersChart {
    width: 35px;
}

.subscribersCard {
    background-color: rgb(248, 146, 51);
    color: #ffffff;
    font-family: 'Varela Round', sans-serif;
    border-radius: 20px;
    padding: 10px
}

.subscribersCardTitle{
    font-size: 20px;
    padding-left: 20px;
}

.subscribersCardQuantity {
    font-size: 25px;
    padding-left: 20px;
    font-weight: 500;
    color: #ffffff;

}

.surroundingSubscribersIcon {
    background-color: rgba(255, 255, 255, .15);
    height: 60px;
    width: 60px;
    border-radius: 9px;
    display: flex;
  align-items: center;
  justify-content: center;
}

.subscribersIcon {
    width: 35px;
    filter: invert(1);
    opacity: 100%;
    padding: auto;
    color: #B5BAC7
}

.subscribersCardDivider {
    border: 1px solid #B5BAC7;
    background-color: #B5BAC7;
    color: #B5BAC7;
    margin-left: 20px;
    margin-right: 20px;
}

.subscribersCardDateRange {
    padding-left: 20px;
    color: #4792FE;
}

.subscribersCardDateRangeSpan {
    color: #4792FE;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.subscribersCardDateRangeArrow {
    padding-top: 20px;
    width: 10px;
    color: #4792FE;
}

.chartContainer {
    padding-left: 10px;
    max-height: 50px;
}