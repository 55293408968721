.subRevenueContainer {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    min-height: 370px;
}

.subRevenueContainer > table {
    border-collapse: collapse;
}

.subRevenueContainer > table > tbody > tr {
    
}

.subRevenueContainer > table > tbody > tr > td {
    width: fit-content;

}

.subRevenueAmountContainer {
    color: #00ab55;
    font-size: 16px;
    border-radius: 15px;
    margin: auto;
    cursor: pointer;
    background-color: rgba(0, 171, 85, 0.078);
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 10px;
    text-align: center;
}

.subRevenueAmount {
    padding: 11px;
    display: block;
    text-decoration: none;
    color: rgb(0, 171, 85);
}