.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
}
  
.tableJSMainDiv {
    height: 94vh;
    padding: 10px;
    overflow-y: auto;
}


.tableJSInputFieldsTable{
    width: 100%;
    table-layout: fixed;
    
}

.tableJSMainTable{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 8px;
    overflow-x: auto;
}

.tableJSMainTableContainer {
    
    max-width: 150vw;
    overflow-y: auto;

}

.tableJSTableHeaders{
    margin-top: 30px;
    font-size: 12px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 0.7);
    text-align: right;
}

.tableDataText{
    font-size: 12px;
    font-family: 'Varela Round', sans-serif;
    text-align: right;
}

.title{
    font-size: 30px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
}

.tableJSRow:nth-child(odd) {background-color: #f7f7f7;}
.tableJSRow:hover {background-color: #f2f2f2;}

.tableJSRow {
    min-height: 2rem;
    height: 2rem;
    text-align: right;
}

.tableJSHeaderRow {
    text-align: right;
}

.tableJSColumn {
    padding-right: 0px;
}




/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .tableJSHideColsOnMobile {
        visibility:hidden;
        width: 0px;
        max-width: 0px;

    }

    .tableDataText{
        font-size: 11px;
        text-align: right;
    }

    .tableJSTableHeaders{
        font-size: 12px;
        color: "rgba(0, 0, 0, 0.87)";
        text-align: right;
    }
    .tableJSInputFieldsTable tr { display: block; float: left; }
    .tableJSInputFieldsTable th, .tableJSInputFieldsTable td { display: block; }

    .tableJSInputFields {
        width: 92vw;
        min-width: 92vw;
        padding-top: 5px;
    }

    .tableJSMainTable{
        width: 200vw;
        table-layout: fixed;
        border-collapse: collapse;
        margin-top: 8px;
        overflow-x: auto;
        overflow-y: auto;
    }

    .tableJSMainTableContainer {
        width: 100%;
        max-width: 92vw;
        overflow-x: auto;
    }
  }



/* DESKTOP */
@media (min-width:1100px) {
    .tableJSInputFields {
        max-width: 100%;
        padding-top: 5px;
    }


  }
