.analyticsPageDiv {
  min-height: 100vh;
  background-color: #f4f3f3;
  display: flex;
}

.analyticsNav {
  min-width: 400px;
  background-color: #ffffff;
  min-height: 100vh;
  font-family: 'Varela Round', sans-serif;
  float: left
}




.analyticsFunnelTabContainer {
  color: #637381;
  font-size: 16px;
  border-radius: 15px;
  width: 85%;
  margin: auto;
  cursor: pointer;
  background-color: rgba(192, 192, 192, 0.08);
  margin-top: 5px;
  margin-bottom: 10px;
}

.analyticsFunnelTabContainerSelected {
  color: #00ab55;
  font-size: 16px;
  border-radius: 15px;
  width: 85%;
  margin: auto;
  cursor: pointer;
  background-color: rgba(0, 171, 85, 0.078);
  margin-top: 5px;
  margin-bottom: 10px;
}

.analyticsFunnelTabContainerTitle {
  padding: 15px;
  display: block;
  text-decoration: none;
}

.analyticsFunnelTabLoadingTextContainer {
  margin-top: 100px;
  vertical-align: middle;
  text-align: center;
  padding: 30px;
}

.analyticsFunnelTabLoadingText {
  color: #00ab55;
  font-size: 18px;
}

.analyticsFunnelTabFunnelNameText {
  display: inline-block;
  width: 70%;
}

.analyticsFunnelTabFunnelOrdersText {
  display: inline-block;
  width: 30%;
  font-size: 12px;
  text-align: right;
}

.analyticsPageChartsTableContainer {
  width: 100%;
  margin: auto;
  flex: left;
}

.analyticsPageChartsContainer {
  display: block;
  width: fit-content;
  margin: auto;
  padding-top: 30px;
}

.analyticsDateRangeSelector {
  margin: auto;
  width: fit-content;
}

.analyticsTableContainer {
  width: 90%;
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
  font-family: 'Varela Round', sans-serif;
  border-radius: 20px;
  color: rgb(92, 92, 92);
  margin-top: 30px;
  font-size: 14px;
}

.analyticsTable {
  width: 100%;
}

.analyticsTableHeaders {
  font-weight: 800;
}
