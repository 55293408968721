


.accountingDetailsTable{
    height: 100vh;
    width: 100%;
}

.productPageNoFunnelSelectedText {
    font-size: 1.5rem;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
}

.productPageNoFunnelSelectedContainer {
    width: 100%;
}

.AccountingPageLoader {
    margin-top: 48vh;
}

/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .productPageTabsDrawerHideBtn {
        width: 100vw;
        min-width: 100vw;
        margin-top: 5px;
    }
    .AccountingDetailsTabsSection {
        width: 100vw;
        height: 100vh;
        min-width: 350px;
    }
  }





/* DESKTOP */
@media (min-width:1400px) {

    .productPageTabsDrawerHideBtnContainer {
        display: none;
        max-height: 0px;
        max-width: 0px;
        visibility:hidden;
    }

    .AccountingDetailsTabsSection {
        width: 18vw;
        max-width: 450px;
        height: 100vh;
        min-width: 200px;
    }

  }

