.AccountingSummaryTitle{
    display: block;
    padding-top: 0px;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color:rgb(0, 171, 85);
    font-weight: 700;
    text-align: center;
}

.accountingSummaryTableSectionContainer {
    color: #3f4951;
    font-size: 16px;
    border-radius: 15px;
    width: 85%;
    margin: auto;
    cursor: default;
    background-color: rgba(88, 87, 87, 0.08);
    margin-top: 0px;
    padding: 5px;
}



.accountingDetailsSummaryTableHeaders {
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    display: block;
    text-align: center;
    font-weight: 600;
}

.accountingDetailsSummaryValue{
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
    display: block;
}

.productSummaryMainTable {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 8px;
    overflow-x: auto;
    
}

.productSummaryMainDiv {
    width: 100%;
    max-width: 93vw
}

.productSummaryTableHeadersContainer {
    width: 100px;
}

.AccountingDetailsMainTableContainer {

}


/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {

  }



/* DESKTOP */
@media (min-width:1100px) {

  }