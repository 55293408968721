.funnelCampaignSelectorTable {
    width: 100%;
    min-width: 100%
}

.funnelCampaignSelectorCampaignDropdown {
    width:100%
}

.funnelCampaignSelectorSpacer {
    min-width: 10px;
}