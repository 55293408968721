.salesByDayOfWeekChartMainContainer {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    margin-left: 0px;
    min-height: 370px;
}

.salesByDayOfWeekChartContainer {

}

.dashboardSalesByDayFunnelNamesContainer {
    overflow-x: auto;
    white-space: nowrap;
}

.activeDashboardSalesByDayFunnelNameContainer {
    background-color: #00ab5514;
    border-radius: 15px;
    width: fit-content;
    padding: 10px;
    margin: 5px;
    color: #00ab55;
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    cursor: pointer;
}

.dashboardSalesByDayFunnelNameContainer {
    background-color: rgba(192, 192, 192, 0.08);
    border-radius: 15px;
    width: fit-content;
    padding: 10px;
    margin: 5px;
    color: #637381;
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    cursor: pointer;
}

.dashboardSalesByDayChartDataContainer {

    overflow-x: auto;
    white-space: nowrap;
}

.salesByDayOfWeekLoadingMainContainer {
    height: 264px;
    width: 100%;
}

.salesByDayOfWeekLoadingContainer {
    margin: auto;
    vertical-align: middle;
    padding-top: 100px;
    width: fit-content;
    text-align: center;
}

.salesByDayOfWeekLoadingText {
    display: block;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
}