.businessSettingsMainContainer {
    width: 70%;
    margin: auto;
    padding-top: 10vh;
    display: flexbox;
}

.businessSettingsTrafficSourcesContainer {
    padding: 10px;
    float: 1;
}

.businessSettingsFacebookBMsContainer {
    padding: 10px;
    float: 2;
}

.businessSettingsCustomDateRefetch {
    padding: 20px;
    margin: 10px;
    float: 3;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    color: rgb(76, 75, 75);
    font-weight: 700;
}