.commissionBackground {
    margin: 0;
    padding: 0;
    background-color: #f4f3f3;
    min-height: 100vh;
    width: 100vw;
    overflow-y: auto;
    font-family: 'Varela Round', sans-serif;
    display: flex;
}

.commissionContainer {
    background-color: #ffffff;
    border-radius: 20px;
    width: 70%;
    margin: auto;
    margin-top: 40px;
    padding: 20px;
}

.commissionDateSelectionContainer {
    overflow-x: auto;
    white-space: nowrap;
}

.commissionDateSelection {
    background-color: rgba(192, 192, 192, 0.08);
    margin-bottom: 10px;
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    width: fit-content;
    display: inline-block;
    margin: 10px;
    color: #637381;
}

.activeCommissionDateSelection {
    background-color: #00ab5514;
    margin-bottom: 10px;
    border-radius: 15px;
    padding: 15px;
    cursor: pointer;
    width: fit-content;
    display: inline-block;
    margin: 10px;
    color: #00ab55;
}

.commissionDateSelectionTitle {
    font-size: 24px;
    display: block;
    margin: auto;
    width: fit-content;
    padding-top: 20px;
    color: #00ab55;
    font-weight: 600;
}

.commissionTable {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
}

.commissionTableHeaders {
    font-weight: 600;
}

.commissionChartsTable {
    display: block;
    width: fit-content;
    text-align: center;
    margin: auto;
    margin-bottom: 40px;
}

.commissionChartsTableSpacer {
    width: 200px;
}

.commissionOrdersChart {
    width: 300px;
    display: block;    
}

.commissionProfitBreakdownChart {
    width: 300px;
    display: block;    
}

.commissionLoadingConatiner {
    width: fit-content;
    margin: auto;
    text-align: center;
    margin-top: 200px;
    color: #00ab55;
    font-size: 18px;
    margin-bottom: 200px;
}
