.userPriveledgesCardContainer {
    border-radius: 20px;
    padding: 30px 20px 20px 20px;
    margin: auto;
    width: 80%;
    background-color: #ffffff;
    margin-top: 95px;
}

.userPermissionsTableHeader {
    padding-bottom: 10px;
    font-weight: 600;
}

.userPermissionsLoadingContainer {
    display: block;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
}