.accountBalancesMainContainer {
  display: flex;
}


.accountBalancesBackground {
  margin: 0;
  padding: 0;
  background-color: #f4f3f3;
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;
  font-family: 'Varela Round', sans-serif;
}

.facebookAccountsContainer {
  width: 80%;
  margin: auto;
  margin-top: 60px;
  background-color: white;
  border-radius: 20px;
  padding: 0px 20px 20px 20px;
}

.tiktokAccountsContainer {
  width: 80%;
  margin: auto;
  margin-top: 40px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.accountBalancesTableWidth {
  width: 100%;
  min-width: 850px;
}

.accountBalancesCardTitle {
  display: block;
  width: fit-content;
  margin: auto;

}

.accountBalancesRowHeight {
  padding-top: 15px;
  padding-bottom: 15px;
}

.accountBalancesFilter {
  position: absolute;
  top: 10px;
  right: 15%;
}

.accountBalancesLoadingContainer {
  padding: 100px;
  text-align: center;
}

.facebookAccountsLoadingText {
  display: block;
  font-size: 16px;
  font-family: 'Varela Round', sans-serif;
  color: rgb(0, 171, 85);
  font-weight: 400;
  text-align: center;
}

.accountBalancesNoActiveAccountsText {
  display: block;
  font-size: 20px;
  font-family: 'Varela Round', sans-serif;
  color: rgb(0, 171, 85);
  font-weight: 400;
  text-align: center;
  padding: 100px;
}

/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
  .facebookAccountsContainer {
    width: 85vw;
    margin: auto;
    margin-top: 60px;
    background-color: white;
    border-radius: 20px;
    padding: 0px 20px 20px 20px;
    max-width: 85vw;
    overflow-x: auto;
  }
  
  .tiktokAccountsContainer {
    width: 85vw;
    margin: auto;
    margin-top: 40px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    max-width: 85vw;
    overflow-x: auto;
  }
}