.dashboardMediaBuyersMainConatiner {
    max-height: 100%;
    min-height: 100%;
    overflow-y: auto;
}

.dashboardMediaBuyersTable {
    color: #212B36;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 20px;
    min-height: 50%;
}

.dashboardMediaBuyersTableSpacer {
    margin: 35px;
}

.dashboardMediaBuyersLoadingTable {
    color: #212B36;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 20px;
    margin: 25px 0px 25px 0px;
    min-height: 450px;
}

.mediaBuyersChartsContainer {
    width: 90%;
    margin: auto;
}


.MediaBuyersTitle {
    font-size: 17px;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
    padding: 10px;
    font-weight: 700;
}

.mediaBuyersDivider {
    border: 1px solid #B5BAC7;
    background-color: #B5BAC7;
    color: #B5BAC7;
    margin: 0;
    margin-left: 20px;
    margin-right: 20px;
}

.mediaBuyersName {
    font-size: 14px;
    font-family: 'Varela Round', sans-serif;
    padding-top: 10px;
    font-weight: 700;
    display: block;
    text-align: center;
    color: #212B36;
}

.mediaBuyersOrderCount {
    font-size: 14px;
    font-family: 'Varela Round', sans-serif;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
    color: #212B36;
}

.mediaBuyersCPA {
    font-size: 14px;
    font-family: 'Varela Round', sans-serif;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
    color: #212B36;
}

.mediaBuyersProfit {
    font-size: 14px;
    font-family: 'Varela Round', sans-serif;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
    color: #212B36;
}

.mediaBuyersCPAMessage {
    font-family: 'Varela Round', sans-serif;
    display: block;
    width: 100%;
    text-align: center;
    color: #212B36;
}

.dashboardMediaBuyersLoadingText {
    display: block;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
    padding-top: 200px
}