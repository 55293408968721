.adSpendChartAnalyticsDiv {
    margin: 10px;
    width: 30vw;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    height: fit-content;
    position: static;
    left: 33%;
}

.adSpendChartAnalyticsTitle {
    margin: auto;
    width: fit-content;
    text-align: center;
    font-family: 'Varela Round', sans-serif;
    display: inline-block;
    font-size: 20px;
}

.adSpendChartAnalyticsOrderTotalContainer {
    position: static;
    width: fit-content;
    top: 10px;
    right: 10px;
    color: #00ab55;
    background-color: #00ab5514;
    padding: 10px;
    border-radius: 10px;
}

.adSpendChartAnalyticsOrderTotal {
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    width: fit-content;
}

.adSpendChartAnalyticsChart {
    margin: 30px 20px 20px 20px;
}

.adSpendChartAnalyticsLoadingContainer {
    margin: auto;
    padding-top: 100px;
    width: fit-content;
    text-align: center;
}

.adSpendChartAnalyticsLoadingText {
    display: block;
    font-size: 18px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
}