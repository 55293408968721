.TabsSection {
    height: 95vh;
}

.productPageTable{
    height: 95vh;
}

.productPageNoFunnelSelectedText {
    font-size: 1.5rem;
    font-family: 'Varela Round', sans-serif;
    color: #1976d2;
    font-weight: 400;
    text-align: center;
}

.productPageNoFunnelSelectedContainer {
    width: 100%;
}

/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .productPageTabsDrawerHideBtn {
        width: 100vw;
        min-width: 100vw;
        margin-top: 5px;
    }
    .TabsSection {
        width: 100vw;
        min-width: 350px;
    }
  }





/* DESKTOP */
@media (min-width:1400px) {

    .productPageTabsDrawerHideBtnContainer {
        display: none;
        max-height: 0px;
        max-width: 0px;
        visibility:hidden;
    }

    .TabsSection {
        width: 18vw;
        min-width: 320px;
    }

  }

