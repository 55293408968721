

.productPagesTabsTable {
    width: 100%;

}

.productPagesTabsContainer {
    overflow-y: auto;
    height: 94vh;
}


.tabRows {
    width: 100%;
}

.tabLeftCol {
    width:67%;
    text-align: left;
}

.tabRightCol{
    width:33%;
    text-align: right;
}

.productName{
    font-size: clamp(1.05rem, -0.875rem + 1.4vw, 2rem);
    font-family: 'Varela Round', sans-serif;
    text-align: left;

}

.detailsText{
    font-size: clamp(.7rem, -0.875rem + 1.1vw, 2rem);
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.descriptionContainer {
    padding-top: 5px;
}

.description{
    font-size: 15px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
    display: inline;
}

a {
    color: #000000;
    text-decoration: none; /* no underline */
  }

  .productPagesTabsSwitch {
    width: 20px;
  }