




.navbarSearchDropdown {
  position:relative;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  color: black;
  max-height: 600px;
  overflow: scroll;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 240px;
  border-radius: 5px;
}

.navbarSearchDropdownElementContainer {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 1); 
  text-align: center;  
}

.navbarSearchDropdownElement {
  padding: 10px;
  border-radius: 5px;
  margin: 0px;
}

.navbarSearchDropdownElement:hover {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 171, 86, 0.191);
  color: #00ab55;
}