.ordersChart {
    width: 35px;
    max-width: 300px
}

.ordersCard {
    background-color: rgb(81, 175, 90);
    color: #ffffff;
    font-family: 'Varela Round', sans-serif;
    border-radius: 20px;
    padding: 10px
}

.ordersCardTitle{
    font-size: 20px;
    padding-left: 20px;
}

.ordersCardQuantity {
    font-size: 25px;
    padding-left: 20px;

}

.surroundingOrdersIcon {
    background-color: rgba(255, 255, 255, .15);
    height: 60px;
    width: 60px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ordersIcon {
    width: 35px;
    filter: invert(1);
    opacity: 100%;
    padding: auto;
    color: rgba(255, 255, 255, 1)
}

.ordersCardDivider {
    border: 1px solid rgba(255, 255, 255, .8);
    background-color: rgba(255, 255, 255, .8);
    color: rgba(255, 255, 255, .8);
    margin-left: 20px;
    margin-right: 20px;
}

.ordersCardDateRange {
    padding: 20px;
    text-align: right;
}

/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
    .ordersChart {
        width: 10px;
        max-width: 100%
    }  

    .ordersCardTitle{
        font-size: 14px;
        padding-left: 20px;
    }
}