.popularProductsTable{
width: 100%;
}

.tableHeaders{
width:100%;
}

.funnelName{
    font-size: 22px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.revenueNumber{
    font-size: 22px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.AdSpendNumber{
    font-size: 22px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
}

.COGSNumber{

}

.PLNumber{

}

.CPANumber{

}
.tableWidth{
    width: 100%;
}

.tableHeaders{
    font-size: 15px;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
}

.tableDataText{
    font-size: 14px;
    font-family: 'Varela Round', sans-serif;
    text-align: right;

}

.title{
    font-size: 30px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    
}

.secondaryTable {
    margin:auto
}

h2 {
    font-family: 'Varela Round', sans-serif; 
}

.headerWidths {
    width:25%
}


