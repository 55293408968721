.productSummaryTitle{
    display: block;
    padding-top: 10px;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color:rgb(0, 171, 85);
    font-weight: 700;
}

.tableBody{
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
}

.productSummaryMainTable {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-top: 8px;
    overflow-x: auto;
    
}

.productSummaryMainDiv {
    width: 100%;
    max-width: 93vw
}

.productSummaryTableHeaders {
    margin-top: 30px;
    font-size: 13px;
    font-family: 'Varela Round', sans-serif;
    color: rgba(0, 0, 0, 0.7);
}

.productSummaryTableHeadersContainer {
    width: 100px;
}

.productSummaryMainTableContainer {
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
}


/* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {

  }



/* DESKTOP */
@media (min-width:1100px) {

  }