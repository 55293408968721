.productPageOptionsButton {
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    margin: auto;
    color: rgb(0, 171, 85);
    cursor: pointer;
    padding: 5px
}

.productPageOptionsButtonText {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Varela Round', sans-serif;
    display: block;
    padding: 5px;
}