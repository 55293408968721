.topFunnelsContainer {
    position:relative;
    min-height: 500px;
    color: #212B36;
    background-color: #ffffff;
    border-radius: 20px;
    z-index: 1;
    max-width: 100vw;
    overflow: auto;
}

.topFunnelsTable {
    width: 96%;
    margin: auto;
    padding-left: 2%;
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 40px;
}

.topFunnelsDataText {
    font-family: 'Varela Round', sans-serif;
}

.topFunnelsRows {
    margin-left: 10px;
    background-color: rgba(192, 192, 192, 0.08);
    border-radius: 15px;
}

.topFunnelsTableHeaders {
    font-family: 'Varela Round', sans-serif;
    text-align: left;
    font-weight: 600;
}

.topFunnelsSpacerRows {
    height: 10px;
}


.topFunnelsStatusText {
    color: rgb(0, 171, 85);
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topFunnelsHeader {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topFunnelsColumn {
    text-align: left;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topFunnelsFilters {
    position:absolute; 
    bottom:0;
}

.topFunnelsNote {

    display: inline-block;
    padding-top: 15px;
    text-align: right;
}

.topFunnelsNoSalesText {
    display: block;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: rgb(0, 171, 85);
    font-weight: 400;
    text-align: center;
    padding-top: 200px
}

.topFunnelsBottomResultsFilterSectionContainer {
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    overflow-x: auto;
    white-space: nowrap;
}

.topFunnelsBottomResultsFilterSection {
    width: fit-content;
    margin: auto;
}

.topFunnelsResultsFiltersContainer {
    padding: 10px 0px 0px 0px;
    display: inline-block;
}

.topFunnelsActiveFilterContainer {
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    width: fit-content;
    color: rgb(0, 171, 85);
    padding: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.topFunnelsFilterContainer {
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    background-color: rgba(192, 192, 192, 0.08);;
    border-radius: 15px;
    width: fit-content;
    color: #637381;
    padding: 15px;
    margin-left: 10px;
    cursor: pointer;
}


.topFunnelsResultsSortByContainer {
    padding: 10px 5px 5px 0px;
    display: inline-block;
}

.topFunnelsActiveSortByContainer {
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    background-color: rgba(0, 171, 85, 0.08);
    border-radius: 15px;
    width: fit-content;
    color: rgb(0, 171, 85);
    padding: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.topFunnelsSortByContainer {
    display: inline-block;
    font-family: 'Varela Round', sans-serif;
    background-color: rgba(192, 192, 192, 0.08);;
    border-radius: 15px;
    width: fit-content;
    color: #637381;
    padding: 15px;
    margin-left: 10px;
    cursor: pointer;
}