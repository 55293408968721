.background{

    max-height:100vh;
    min-height:100vh;
    width:100vw;
    background: rgb(140,205,0);
    background: linear-gradient(90deg, rgba(140,205,0,1) 0%, rgba(0,171,85,1) 0%, rgba(0,226,255,1) 100%);
    overflow-y: hidden;
  }

  .loginCardContainer {
    max-width: 600px;
    padding: 60px 20px 60px 20px;
    max-height: 400px;
    background-color: #ffffff;
    border-radius: 20px;
  }

  .emailTextBoxDiv{
    width:80%;
  }

  .emailTextBox{
    width:100%;
  }

  .passwordTextBoxDiv{
    width:80%;
    padding: 20px;
  }
  
  .passwordTextBox{
    width:100%;
  }
  
  .loginCardStyle{
    padding-top: 25vh;
  }

  .loginButtonTable{
    width: 80%;
    margin: 20px;
  }

  .signUpButton{
    width:100%;
  }

  .signInButton{
    width:100%;
  }
  
  .googleBtnDiv{
    margin: 20px;
    color: #000000;
  }

  .loginPageWebsiteTitle {
    font-size: 1.5rem;
    font-family: 'Varela Round', sans-serif;
    color: #00ab55;
    font-weight: 400;
  }



  /* MOBILE */
@media (min-width:0px) and (max-width: 1100px) {
  .loginCardStyle{
    padding-top: 15vh;
  }
}